import './hbspt-form.scss';
import { getOffset } from './../../utilities/js/helper';

class HbsptForm {
    constructor ($element, formId, options) {
        const defaults = {
            filledClassname: 'is--filled',
            focusedClassname: 'is--focused',
            errorClassname: 'has--error',
            submitBtnSelector: '[type="submit"]'
        };

        this.settings = Object.assign({}, defaults, options);

        this.$form = $element;
        this.formId = formId;
        this.$formFields = this.$form.querySelectorAll('select, input, textarea');
        this.$submitButton = this.$form.querySelector(this.settings.submitBtnSelector);
        this.$formAnchor = document.getElementById('hubspot-form-' + this.formId);
    }

    initialize () {
        const $root = this.$form.closest('[data-hubspot="root"]');
        if ($root) {
            const $loading = $root.querySelector('.loading');
            if ($loading) {
                $loading.parentNode.removeChild($loading);
            }
        }

        this.changeSubmitButton();
        this.setEvents();
    }

    changeSubmitButton () {
        const submitText = this.$submitButton.value;
        const $newButton = document.createElement('button');
        $newButton.setAttribute('type', 'submit');
        $newButton.classList.add('button');
        $newButton.innerText = submitText;

        this.$submitButton.replaceWith($newButton);
    }

    setEvents () {
        this.$form.addEventListener('change', (e) => {
            const $element = e.target;
            this.checkField($element);
        });

        window.addEventListener('form.submitted', (e) => {
            const eventDetails = e.detail;
            const $form = eventDetails.$context;

            if ($form) {
                const anchorDimensions = getOffset(this.$formAnchor);
                let anchorDimensionsTop = anchorDimensions.top;

                const $header = document.querySelector('[data-siteheader="root"]');
                if ($header) {
                    const headerHeight = $header.offsetHeight;
                    anchorDimensionsTop = anchorDimensionsTop - headerHeight;
                }

                window.scrollTo({
                    top: anchorDimensionsTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        });

        this.$formFields.forEach($field => {
            let fieldTimeout = null;

            $field.addEventListener('blur', () => {
                clearTimeout(fieldTimeout);
                fieldTimeout = window.setTimeout(() => {
                    this.checkField($field);
                }, 100);
            });
        });
    }

    checkField ($field) {
        let formType = $field.getAttribute('type');

        if (formType === null) {
            formType = $field.tagName.toLowerCase();
        }

        switch (formType) {
        case 'select': {
            let filled = true;
            const $element = $field.closest('.hs-fieldtype-select');

            if ($field.value === '') {
                filled = false;
            }

            if ($field.value === 0 || $field.value === '0') {
                const $emptyOption = $field.querySelector('option[value="0"]');
                if ($emptyOption.innerText === '') {
                    filled = false;
                }
            }

            if (filled === false) {
                $element.classList.remove(this.settings.filledClassname);
            } else {
                $element.classList.add(this.settings.filledClassname);
            }

            if ($field.classList.contains('error')) {
                $element.classList.add(this.settings.errorClassname);
            } else {
                $element.classList.remove(this.settings.errorClassname);
            }

            break;
        }
        case 'radio': {
            break;
        }
        case 'checkbox': {
            break;
        }
        case 'submit': {
            break;
        }
        default: {
            const $element = $field.closest('.hs-form-field');

            if ($field.value !== '') {
                $element.classList.add(this.settings.filledClassname);
            } else {
                $element.classList.remove(this.settings.filledClassname);
            }

            if ($field.classList.contains('error')) {
                $element.classList.add(this.settings.errorClassname);
            } else {
                $element.classList.remove(this.settings.errorClassname);
            }
        }
        }
    }
}

export { HbsptForm };

window.addEventListener('form.loaded', (e) => {
    const eventDetails = e.detail;
    const $form = eventDetails.$context;
    const formId = eventDetails.formId;

    if ($form && formId) {
        const hbsptForm = new HbsptForm($form, formId);
        hbsptForm.initialize();
    }
});
